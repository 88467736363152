import { useCallback } from "react";
import { getCookie } from "../utils/api";
import { useLoginPopup } from "../context/LoginPopupContext";
import envConfig from "../config";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

export const checkAndUpdateApiKeyExpiration = () => {
  const apikeyexpirationdate = getCookie("apiKeyExpirationUTCDate");
  const expirationDate = new Date(apikeyexpirationdate + "Z");
  const now = new Date();

  const timeDifference = expirationDate.getTime() - now.getTime();
  const prolongByShort = envConfig.loginUrl.includes(".app")
    ? 2 * 60 * 1000
    : 15 * 60 * 1000;
  const expiryPeriodLong = 3 * 24 * 60 * 60 * 1000; // 3 days

  let newExpirationDate;
  if (timeDifference <= prolongByShort) {
    newExpirationDate = new Date(now.getTime() + prolongByShort);
  } else {
    newExpirationDate = new Date(now.getTime() + expiryPeriodLong);
  }

  Cookies.set(
    "apiKeyExpirationUTCDate",
    newExpirationDate.toISOString().split(".")[0],
    {
      path: "/",
    },
  );
};

const useApi = () => {
  const { handleOpen, waitForReAuth } = useLoginPopup();
  const { dbId } = useParams();

  const apiCall = useCallback(
    async ({
      url,
      method = "GET",
      ip = "31.42.0.186",
      headers = {},
      body = null,
      onSuccess = () => {},
      onError = () => {},
      signal = null,
      skipAuth = false,
    }) => {
      const makeRequest = async () => {
        try {
          const defaultHeaders =
            Object.keys(headers).length > 0
              ? headers
              : {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "olivs-api-real-ip": ip,
                  "olivs-api-computer-name": "BTM-WS2019",
                  "session-id": getCookie("sessionId"),
                  "browser-agent": window.navigator.userAgent,
                  "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
                  "api-key":
                    getCookie("apiKey") +
                    "-" +
                    (dbId || getCookie("databaseId")),
                };

          const response = await fetch(url, {
            method,
            headers: defaultHeaders,
            body: body ? JSON.stringify(body) : null,
            signal,
          });

          let data;
          try {
            data = await response.json();
          } catch (e) {
            data = await response.text();
          }

          if (
            (response.status === 401 || response.status === 403) &&
            !skipAuth
          ) {
            // Trigger re-authentication
            if (process.env.NODE_ENV !== "development") {
              await handleOpen();
              await waitForReAuth();
              return await makeRequest(); // Retry the API call after re-authentication
            }
          } else if (!response.ok) {
            // Handle other non-2xx responses
            const errorMessage =
              (typeof data === "string" && data) ||
              data.ErrorMessage ||
              data.errorMessage ||
              data.message ||
              "An error occurred";
            onError(errorMessage, data);
          } else {
            // Successful response
            if (
              data.SuccessYN === "Y" ||
              data.successYN === "Y" ||
              data.SmsSentYN === "Y" ||
              data.Secret2FAKey ||
              data.User2FADisabledYN ||
              data.User2FAEnabledYN
            ) {
              if (
                url.includes(envConfig.apiDev1) ||
                url.includes(envConfig.apiDev2)
              ) {
                console.log(response.headers);
                const apiKeyExpirationDate = response.headers.get(
                  "apikeyexpirationdate",
                );

                console.log(apiKeyExpirationDate);
                checkAndUpdateApiKeyExpiration();
              }
              onSuccess(data);
            } else {
              const errorMessage =
                data.ErrorMessage || data.errorMessage || "An error occurred";
              onError(errorMessage, data);
            }
          }
        } catch (error) {
          const errorMessage = error.message || "An error occurred";
          onError(errorMessage);
          if (!skipAuth) {
            await handleOpen();
          }
        }
      };

      return await makeRequest();
    },
    [handleOpen, waitForReAuth, dbId],
  );

  return { apiCall };
};

export default useApi;
