import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { UnprocessedTransactionsSuggestion } from "../types";

export const UnprocessedTransactionsTableSuggestionsStatus = ({
  status,
}: {
  status: number;
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case status >= 30:
        return "success.main";
      case status >= 10 && status < 30:
        return "success.dark";
      case status > 0 && status < 10:
        return "warning.main";
      case 0:
        return "error.main";
      default:
        return "text.primary";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case status >= 30:
        return "✓✓";
      case status >= 10 && status < 30:
        return "✓";
      case status > 0 && status < 10:
        return "!";
      case 0:
        return "×";
      default:
        return "";
    }
  };

  const getStatusText = (status) => {
    if (status >= 30) {
      return "Very High";
    } else if (status >= 10 && status < 30) {
      return "High";
    } else if (status > 0 && status < 10) {
      return "Medium";
    } else if (status === 0) {
      return "Low";
    } else {
      return "";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: 1,
        borderColor: getStatusColor(status),
        borderRadius: 50,
        px: 2,
        py: 0.5,
      }}
    >
      <Typography
        variant="body2"
        fontWeight={"bold"}
        sx={{ color: getStatusColor(status), mr: 0.5 }}
      >
        {getStatusIcon(status)}
      </Typography>
      <Typography
        variant="body2"
        fontWeight={"bold"}
        sx={{ color: getStatusColor(status) }}
      >
        Probability : {getStatusText(status)}
      </Typography>
    </Box>
  );
};

export const UnprocessedTransactionsTableSuggestions = ({
  suggestions = [],
  isDarkMode = false,
  onFindAllWithRule,
  onApplySuggestion,
  isApplyLoading,
}: {
  suggestions: UnprocessedTransactionsSuggestion[];
  isDarkMode?: boolean;
  onFindAllWithRule: (rule: any) => void;
  onApplySuggestion: (suggestion: any) => void;
  isApplyLoading: boolean;
}) => {
  return (
    <Box>
      {suggestions.map((suggestion) => (
        <Box
          key={suggestion.CntID}
          p={2}
          display="flex"
          alignItems={"center"}
          gap={2}
          borderRadius={2}
          border={isDarkMode ? "1px solid #3e424d" : "1px solid #e0e0e0"}
        >
          <Box>
            <UnprocessedTransactionsTableSuggestionsStatus
              status={suggestion.MatchRanking}
            />
          </Box>
          <Box flex="1">{suggestion.MatchMemo}</Box>
          <Box display="flex" alignItems={"center"} gap={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => onApplySuggestion(suggestion)}
              disabled={isApplyLoading}
            >
              {isApplyLoading ? "Applying..." : "Apply"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => onFindAllWithRule(suggestion)}
            >
              Find all with this rule
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
