import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";

interface DisregardTransactionDialogProps {
  open: boolean;
  onClose: () => void;
}

const DisregardTransactionDialog: React.FC<DisregardTransactionDialogProps> = ({
  open,
  onClose,
}) => {
  const [reason, setReason] = React.useState<string>("duplicate");
  const [additionalInfo, setAdditionalInfo] = React.useState<string>("");

  const handleSubmit = () => {
    // Handle the submission logic here
    console.log("Reason:", reason);
    console.log("Additional Info:", additionalInfo);
    alert("Add missing API call here!");
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Disregard Transaction</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          This transaction will not be posted to your ledgers, but will remain
          in bank feeds. Please provide information why are you disregarding
          this transaction:
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="disregard-reason"
            name="disregard-reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <FormControlLabel
              value="duplicate"
              control={<Radio />}
              label="Duplicated transaction"
            />
            <FormControlLabel
              value="processed-differently"
              control={<Radio />}
              label="Transaction processed differently in Exacc, e.g., split or merged with another transaction"
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Other reason"
            />
          </RadioGroup>
          <TextField
            label="Enter a note for future reference"
            multiline
            rows={4}
            variant="outlined"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            fullWidth
            margin="normal"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          // @ts-ignore
          variant="secondary"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          // @ts-ignore
          variant="primary"
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisregardTransactionDialog;
