import { Box } from "@mui/material";
import DataGrid, { Column, Selection } from "devextreme-react/cjs/data-grid";
import React, { forwardRef } from "react";
import { UnprocessedTransactions } from "../types";
import { UnprocessedTransactionsTableRow } from "./process-transactions-table-row";

interface ProcessTransactionsDataGridProps {
  data: UnprocessedTransactions[];
  selectedRowKeys: String[];
  onSelectionChanged?: (e: any) => void;
}

export const ProcessTransactionsDataGrid = forwardRef<
  DataGrid,
  ProcessTransactionsDataGridProps
>(({ data, onSelectionChanged, selectedRowKeys }, ref) => {
  console.log("data", data);

  return (
    <tr>
      <Box>
        <DataGrid
          ref={ref}
          keyExpr="ID"
          dataSource={data}
          noDataText="No Bank Feed Transactions found"
          onSelectionChanged={onSelectionChanged}
          selectedRowKeys={selectedRowKeys}
          columnAutoWidth={true}
          showBorders
          showRowLines
          showColumnHeaders={false}
          selection={{
            mode: "multiple",
            allowSelectAll: true,
            showCheckBoxesMode: "always",
          }}
          dataRowRender={(params) => (
            <UnprocessedTransactionsTableRow
              selectedRowKeys={selectedRowKeys}
              onSelectionChanged={onSelectionChanged}
              {...params}
            />
          )}
          repaintChangesOnly={true}
        >
          <Selection mode="multiple" showCheckBoxesMode="always" />

          <Column dataField="Description" />
          <Column
            dataField="Amount"
            dataType="number"
            format="currency"
            visible={false}
          />
          <Column
            dataField="TransactionDate"
            dataType="date"
            format="shortDate"
            visible={false}
            // cellRender={(params) => (
            //   <UnprocessedTransactionsTableRow {...params} />
            // )}
          />
          <Column
            dataField="AccNo"
            caption="Account Number"
            visible={false}
            // cellRender={(params) => (
            //   <UnprocessedTransactionsTableRow {...params} />
            // )}
          />
          <Column
            dataField="SubClassInfo"
            caption="Sub Class Info"
            visible={false}
          />
        </DataGrid>
      </Box>
    </tr>
  );
});
