import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DataGrid, { Column, Selection } from "devextreme-react/cjs/data-grid";
import { YNToBool } from "../../../../utils/format-bool";
import { getAlignment } from "../../../../helpers/table";
import useApi from "../../../../hooks/useApi";
import envConfig from "../../../../config";
import { enqueueSnackbar } from "notistack";

interface Transaction {
  TrID: number;
  Date: string;
  Amount: number;
  Memo: string;
}

const dummyData: Transaction[] = [
  { TrID: 1, Date: "2023-05-01", Amount: 100.0, Memo: "Purchase" },
  { TrID: 2, Date: "2023-05-02", Amount: 50.0, Memo: "Refund" },
  { TrID: 3, Date: "2023-05-03", Amount: 75.5, Memo: "Service" },
];

const ProcessTransactionsMatchTransactionsDialog = ({
  isOpen,
  transactionName,
  transactionId,
  onClose,
  columns,
  data,
}: {
  isOpen: boolean;
  transactionName: string;
  transactionId: string | number;
  onClose: () => void;
  columns: any[];
  data: any[];
}) => {
  const dataGridRef = useRef<DataGrid>(null);
  const { apiCall } = useApi();

  const [isMatchingTransactions, setIsMatchingTransactions] = useState(false);

  const handleMatchTransactions = async () => {
    if (dataGridRef.current) {
      const selectedRowKeys = dataGridRef.current.instance.getSelectedRowKeys();

      setIsMatchingTransactions(true);

      console.log("handleMatchTransactions: body:", {
        BftID: transactionId,
        Bnk0111: "",
        TransactionGuid: selectedRowKeys.map((rowKey: any) => rowKey.TrID),
      });

      await apiCall({
        url: `https://${envConfig.apiDev2}/bank-feeds/save-matched-transaction-rs1?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        // @ts-ignore
        body: {
          BftID: transactionId,
          Bnk0111: "",
          TransactionGuid: selectedRowKeys.map((rowKey: any) => rowKey.TrID),
        },
        // @ts-ignore
        onSuccess: async (response: any) => {
          setIsMatchingTransactions(false);
          console.log("Matched transactions response: ", response);
          enqueueSnackbar("Transactions matched successfully", {
            variant: "success",
          });
          onClose();
        },
        // @ts-ignore
        onError: async (error: any) => {
          setIsMatchingTransactions(false);
          console.error("Failed to match transactions: ", error);
          enqueueSnackbar(
            `Failed to match transactions: ${error || "Unknown error"}`,
            { variant: "error" },
          );
        },
      });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Transaction: {transactionName}</DialogTitle>
        <DialogContent>
          <DataGrid
            ref={dataGridRef}
            // dataSource={dummyData}
            dataSource={data}
            showBorders={true}
            selection={{ mode: "multiple" }}
          >
            <Selection mode="multiple" />
            {columns?.length &&
              columns.map((column, index) => (
                <Column
                  key={index}
                  dataField={column.RpcDataSource}
                  caption={column.RpcHeader}
                  allowResizing={YNToBool(column.RpcAllowResizing)}
                  minWidth={column.RpcMinWidth}
                  allowFiltering={YNToBool(column.RpcFilter)}
                  alignment={getAlignment(column.RpcAlign)}
                  allowSearch={YNToBool(column.RpcSearchable)}
                  allowSorting={YNToBool(column.RpcSort)}
                  visible={YNToBool(column.RpcVisible)}
                  fixed={YNToBool(column.RpcFreeze)}
                  // width={getColumnWidth(column)}
                />
              ))}
          </DataGrid>
        </DialogContent>
        <DialogActions>
          <Button
            // @ts-ignore
            variant={"outline"}
            size={"small"}
            onClick={onClose}
            disabled={isMatchingTransactions}
          >
            Close
          </Button>
          <Button
            size="small"
            variant={"contained"}
            onClick={handleMatchTransactions}
            disabled={isMatchingTransactions}
          >
            {isMatchingTransactions
              ? "Matching Transactions..."
              : "Match Transactions"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProcessTransactionsMatchTransactionsDialog;
